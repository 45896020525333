import React, { useEffect, useState } from "react";
import Breadcrumb from './Breadcrumb';
import Productslider from './Productslider';
import axios from 'axios'
import { Link } from 'react-router-dom'

function Uploadimagecontent() {

    const [file, setFile] = useState(null);
    const [logo, setLogo] = useState('');
    const [title, setTitle] = useState('');

    useEffect(() => {
      loadLogo();
   }, []);
    

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
 
  const loadLogo=()=>{
    fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/logo", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          if (result.data != "") {
            const data = result.data;
            setLogo(data);
            setTitle(data.title);
            //console.log(data.title);
          }
        })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const handleSubmit = async(event) => {
    event.preventDefault();

    try {
        const formDataObj = new FormData();
        formDataObj.append('file', file);
        formDataObj.append('title', title);
  
        const response = await axios.post('https://apimenutwo.haikutheasiankitchen.com/index.php/Api/updatelogo', formDataObj, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        //window.location.href = '/menu-grid';
        //if(file!=null){ 
           alert("Data updated successfully");
        //}
      } catch (error) {
        console.error('Error uploading file:', error);
      }

  };

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setTitle(event.target.value);
        //console.log(event.target.value)
      };

        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12" >
                     <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item"><Link to="/"><i className="material-icons">home</i> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Update Theme Details</li>
                        </ol>
                    </nav>
                      
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header">
                                <h6>Update Theme Details</h6>
                            </div>
                            <div className="ms-panel-body">
                                <form className="needs-validation clearfix"  onSubmit={handleSubmit} key="addform">
                                    <div className="form-row">
                           
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="title">Title</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="title" placeholder="title" required name="title" defaultValue={title} onChange={handleInputChange}/>
                                                <div className="invalid-feedback">
                                                    Title
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom12">Upload Logo</label>
                                            <div className="custom-file">
                                                <input type="file"  name="image" id="validatedCustomFile" onChange={handleFileChange} accept="image/*"/>
                                                {/* <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Image...</label> */}
                                            </div>
                                            <img className="mt-3" src={logo.image} style={{height: "100px"}}/>
                                        </div>
                                        
                                        
                                        <div className="ms-panel-header new">
                                          <button className="btn btn-primary d-block" type="submit" >Save</button>
                                        </div>
                                     
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );

}

export default Uploadimagecontent;