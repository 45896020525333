import React, { useEffect, useState } from "react";
import Breadcrumb from './Breadcrumb';
import Productslider from './Productslider';
import axios from 'axios'
import { Link } from 'react-router-dom'

function Importfilecontent() {

    const [file, setFile] = useState(null);
    
    useEffect(() => {
    }, []);


  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
 

  const handleSubmit = async(event) => {
    event.preventDefault();

    try {
        const formDataObj = new FormData();
        formDataObj.append('file', file);
  
        const response = await axios.post('https://apimenutwo.haikutheasiankitchen.com/index.php/Api/uploadfile', formDataObj, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        //window.location.href = '/menu-grid';
        //console.log(response.status)
        if(file!=null){ 
           alert("File uploaded successfully");
        }else{
           alert("Please upload CSV file");
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }

  };

        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12" >
                     <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item"><Link to="/"><i className="material-icons">home</i> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Import FIle</li>
                        </ol>
                    </nav>
                      
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header">
                                <h6>Import File</h6>
                            </div>
                            <div className="ms-panel-body">
                                <form className="needs-validation clearfix"  onSubmit={handleSubmit} key="addform">
                                    <div className="form-row">
                          
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom12">Upload CSV File</label>
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" name="image" id="validatedCustomFile" onChange={handleFileChange} accept="text/csv"/>
                                                <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Images...</label>
                                                <div className="invalid-feedback">Example invalid custom file feedback</div>
                                            </div>
                                        </div>
                                        
                                        <div className="ms-panel-header new">
                                          <button className="btn btn-primary d-block" type="submit" >Save</button>
                                        </div>
                                     
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        );

}

export default Importfilecontent;