import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Preloader from './components/layouts/Preloader';
import Dashboard from './components/pages/Dashboard';
//import Accordions from './components/pages/Accordions';
import Addproduct from './components/pages/Addproduct';
import Editproduct from './components/pages/Editproduct';
// import Alerts from './components/pages/Alerts';
// import Animations from './components/pages/Animations';
// import Badges from './components/pages/Badges';
// import Basictables from './components/pages/Basictables';
// import Breadcrumbs from './components/pages/Breadcrumbs';
// import Buttons from './components/pages/Buttons';
// import Cards from './components/pages/Cards';
// import Chartjs from './components/pages/Chartjs';
// import Chat from './components/pages/Chat';
// import Cropper from './components/pages/Cropper';
// import Customerlist from './components/pages/Customerlist';
// import Customerreview from './components/pages/Customerreview';
// import Datatables from './components/pages/Datatables';
// import Draggables from './components/pages/Draggables';
// import Email from './components/pages/Email';
// import Flaticons from './components/pages/Flaticons';
// import Fontawesome from './components/pages/Fontawesome';
// import Formelements from './components/pages/Formelements';
// import Formlayouts from './components/pages/Formlayouts';
// import Formvalidation from './components/pages/Formvalidation';
// import Formwizard from './components/pages/Formwizard';
// import Googlemaps from './components/pages/Googlemaps';
// import Invoicedetail from './components/pages/Invoicedetail';
// import Invoicelist from './components/pages/Invoicelist';
// import Materialize from './components/pages/Materialize';
import Menucatalogue from './components/pages/Menucatalogue';
import Menugrid from './components/pages/Menugrid';
import Menulist from './components/pages/Menulist';
// import Modals from './components/pages/Modals';
// import Googlechart from './components/pages/Googlechart';
// import Orders from './components/pages/Orders';
// import Pagination from './components/pages/Pagination';
// import Preloaders from './components/pages/Preloaders';
import Productdetail from './components/pages/Productdetail';
// import Progressbars from './components/pages/Progressbars';
// import Rangeslider from './components/pages/Rangeslider';
// import Rating from './components/pages/Rating';
// import Restaurantlist from './components/pages/Restaurantlist';
// import Sales from './components/pages/Sales';
// import Sliders from './components/pages/Sliders';
// import Socialactivity from './components/pages/Socialactivity';
// import Sweetalerts from './components/pages/Sweetalerts';
// import Tabs from './components/pages/Tabs';
// import Toast from './components/pages/Toast';
// import Todolist from './components/pages/Todolist';
// import Tour from './components/pages/Tour';
// import Typography from './components/pages/Typography';
// import Vectormaps from './components/pages/Vectormaps';
// import Widgets from './components/pages/Widgets';
// import Clientmanagement from './components/pages/Clientmanagement';
// import Comingsoon from './components/pages/Comingsoon';
// import Defaultlogin from './components/pages/Defaultlogin';
import Login from './components/pages/Login';
// import Defaultregister from './components/pages/Defaultregister';
// import Error from './components/pages/Error';
// import Faq from './components/pages/Faq';
// import Invoice from './components/pages/Invoice';
// import Lockscreen from './components/pages/Lockscreen';
// import Modallogin from './components/pages/Modallogin';
// import Modalregister from './components/pages/Modalregister';
// import Portfolio from './components/pages/Portfolio';
// import Stockmanagement from './components/pages/Stockmanagement';
// import Userprofile from './components/pages/Userprofile';
// import Webanalytics from './components/pages/Webanalytics';
import PrivateRoute from './PrivateRoute';
import Testing from "./Testing";
import Home from "./frontend/Home";
import Ordermenu from './frontend/Ordermenu';
import MenuDetails from './frontend/Details';
import Landing from './frontend/Landing';
import MenuSelection from './frontend/MenuSelection';
import Addcategory from './components/pages/Addcategory';
import Category from './components/pages/Category';
import Editcategory from './components/pages/Editcategory';
import Importfile from './components/pages/Importfile';
import Uploadlogo from './components/pages/Uploadlogo';
import Report from './components/pages/Report';
import Categoryorder from './components/pages/Categoryorder';

function App() {
  const isAuthenticated = localStorage.getItem('token') ? true:false;
  localStorage.setItem('domain','https://apimenutwo.haikutheasiankitchen.com/');
  return (
    // <Router basename={'/templatemonster/react/foodtech'}></Router>
    
    <Router >
       {/* <Router basename={'/haiku'}> */}
      <Preloader/>
      <Switch>
        <Route exact path="/" component={Landing} />
        <PrivateRoute exact path="/Dashboard" component={Dashboard} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/menu-catalogue" component={Menucatalogue} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/menu-grid" component={Menugrid} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/menu-list" component={Menulist} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/add-product" component={Addproduct} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/product-detail/:id" component={Productdetail} isAuthenticated={isAuthenticated}/>
        <PrivateRoute exact path="/edit-product/:id" component={Editproduct} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/add-category" component={Addcategory} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/edit-category/:id" component={Editcategory} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/importfile" component={Importfile} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/updatelogo" component={Uploadlogo} isAuthenticated={isAuthenticated} />
        <PrivateRoute exact path="/category-order" component={Categoryorder} isAuthenticated={isAuthenticated} />
        <Route path="/menu-details/:name" component={MenuDetails} />
        <Route path="/login" component={Login} />
        <Route path="/menu" component={Ordermenu} />
        <Route path="/home" component={Home} />
        <Route path="/selection" component={MenuSelection} />
        <Route path="/category" component={Category} />
        <Route path="/report" component={Report} />
        <Route exact path="/Dashboard" component={Dashboard} />
        <Route exact path="/test" component={Testing} />
        
      </Switch>
    </Router>

    

  );
}

export default App;
