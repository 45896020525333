import React, { Component ,useState,useEffect} from 'react';
// import cloneDeep from "lodash/cloneDeep";
// import throttle from "lodash/throttle";
// import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import '../../../DataTable.css';

function CategoryTable() {

    // const tableHead = {
    //     id: "SlNo",
    //     category_name: "Category",
    //     name: "Sub Category",

    //   };
    // const allData = [
    //     {
    //       name: "Sale",
    //       parentId: "12",
    //       campaignType: "Push",
    //       action: ":"
    //     },
    //     {
    //       name: "Sale2",
    //       parentId: "123",
    //       campaignType: "Inapp",
    //       action: ":"
    //     },

    //   ];

      const itemsPerPage  = 10;
      const [data, setAllData] = useState([]);
      const [currentPage, setCurrentPage] = useState(1);

    // Calculate total number of pages
    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Calculate start and end index of current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // Get current page data
    const currentData = data.slice(startIndex, endIndex);

    // Function to handle page change
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    //   const [value, setValue] = React.useState("");
    //   const [currentPage, setCurrentPage] = React.useState(1);
    //   const [collection, setCollection] = React.useState(
    //     cloneDeep(allData.slice(0, countPerPage))
    //   );
    //   const searchData = React.useRef(
    //     throttle(val => {
    //       const query = val.toLowerCase();
    //       setCurrentPage(1);
    //       const data = cloneDeep(
    //         allData
    //           .filter(item => item.name.toLowerCase().indexOf(query) > -1)
    //           .slice(0, countPerPage)
    //       );
    //       setCollection(data);
    //     }, 400)
    //   );
    
    useEffect(() => {
        loadcategory();
        // if (!value) {
        //   updatePage(1);
        // } else {
        //   searchData.current(value);
        // }
      }, []);
    


      const loadcategory=()=>{
        fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/allcategories", {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
              //"Content-type": "application/json; charset=UTF-8",
            },
          })
            .then((res) => res.json())
            .then((result) => {
              //console.log(result);
              if (result.data != "") {
                const data = result.data;
                setAllData(data);
              }
            })
          .catch((err) => {
            console.log(err.message);
          });
        }

        const handleDelete=(cid)=>{
          // setGridboxesdata((oldValues) => oldValues.filter((item) => item.id !== pid));

          fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/removecategory", {
              method: "POST",
              body: JSON.stringify({
                  id: cid,
              }),
              headers: {
                Accept: "application/json",
                "Content-type": "application/json; charset=UTF-8",
              },
            })
              .then((res) => res.json())
              .then((result) => {
                alert(result.data);
                loadcategory();
              })
            .catch((err) => {
            });
      }
 
        return (
            <div className="col-12">
                <div className="ms-panel">
                    <div className="ms-panel-header">
                       
                        <div class="container">
                          <div class="row">
                              <div class="col-md-10">
                              <h6>Category</h6>
                              </div>
                              <div class="col-md-2">
                                <a href="/add-category"><button className="addcategory">Add Category</button></a>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div className="ms-panel-body">
                        <div className="table-responsive1">
                      
                            <table className="table table-hover thead-primary" >
                                <thead>
                                <tr >
                                    <td >SlNo</td>
                                    <td >Category</td>
                                    {/* <td >Sub Category</td> */}
                                    <td >Action</td>
                                </tr>
                                </thead>
                                {currentData.map((data, i) => (
                                    <tr key={i+1}>
                                    <td >{i+1}</td>
                                    {/* <td >{data.category_name}</td> */}
                                    <td >{data.name}</td>
                                    <td ><a href={"/edit-category/"+data.id}><i className="fas fa-edit  fs-16" /></a> 
                                     <a href="#" onClick={() => handleDelete(data.id)}><i className="fas fa-trash  fs-16" /></a></td>
                                    </tr>
                                    ))}
                            </table>

                            <div>
                            {Array.from({ length: totalPages }, (_, index) => (
                            <button className="paginationCategory" key={index} onClick={() => handlePageChange(index + 1)}>
                                {index + 1}
                            </button>
                            ))}
                        </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        );

}

export default CategoryTable;