import React, { useEffect, useState } from "react";
import Header from './Header.js';
import Stickyheader from './Stickyheader.js';
import "./App.css";
import veg from '../frontend/veg.jpg';
import nonveg from '../frontend/non-veg.png';
import egg from '../frontend/egg.jpg';
import filter from "../frontend/filter.png";
import { useParams } from "react-router";
import search from "../frontend/search.png";
import { Helmet } from "react-helmet";
import { Modal } from "react-bootstrap";

const Ordermenu = () => {
  const params = useParams();
  const id= 1;//params.id.toString();
  const mainDomain = localStorage.getItem('domain');

  const [activeStates, setActiveStates] = useState(Array(50).fill(false));

  const [scrolltop, setScrolltop] = useState(Array(50).fill(false));

  const [productData, setproductData] = useState([]);
  const [allProductData, setAllProductData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [instastory, setInstastories] = useState('');

  useEffect(() => {
    productsData();
    //instastories();
  }
  , []);


//   const instastories=()=>{
//     fetch("https://haikutheasiankitchen.com/wp-json/haikutheasiankitchen/v1/webstories-api", {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-type": "application/json; charset=UTF-8",
//       },
//     })
//       .then((res) => res.json())
//       .then((result) => {
//         //console.log(result);
//         if (result.data != "") {
//           setInstastories(result.data);
       
//         }
//       })
//     .catch((err) => {
//       console.log(err.message);
//     });
// }

  const productsData=()=>{
    fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/allProductsdata", {
      method: "POST",
      body: JSON.stringify({
        main_category_id: id,
    }),
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.data != "") {
          setproductData(result.data);
          setAllProductData(result.data.sub_cat);
        }
      })
    .catch((err) => {
      console.log(err.message);
    });
}

// Function to toggle the active state for a specific item
const toggleAccordion = (index) => {
  setActiveStates(prevStates => {
    const newStates = [...prevStates];
    newStates[index] = !newStates[index];
    return newStates;
  });
};

const linkredirect=(index)=>{
  setScrolltop(prevStates => {
    const newStates = prevStates.map((state, i) => i === index);
    return newStates;
  });
}


   const [checkedItems, setCheckedItems] = useState({}); // State to hold checked status of checkboxes

    // Function to handle checkbox change
    const handleCheckboxChange = (event) => {
      const { name, checked, value } = event.target;
      const updatedCheckedItems = { ...checkedItems, [name]: checked ? value : undefined };
      setCheckedItems(updatedCheckedItems);
      //console.log(updatedCheckedItems);
      productsDatabyFilters(updatedCheckedItems);
    };

    let timeoutId;

    const handleSearch = (event) => {
      const { value } = event.target;
      setSearchInput(value);
      clearTimeout(timeoutId); // Clear any previously scheduled call
        searchData();
    };

    const searchData=(event)=>{
      fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/searchData", {
        method: "POST",
        body: JSON.stringify(
          { 
            search:searchInput,
            main_category_id: id
          }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          if (result.data != "") {
            setproductData(result.data);
          }
        })
      .catch((err) => {
        console.log(err.message);
      });
    }

  const productsDatabyFilters=(data)=>{
    fetch("https://apimenutwo.haikutheasiankitchen.com/index.php/Api/productsDataByFilter", {
      method: "POST",
      body: JSON.stringify({...data,main_category_id: id}),
      headers: {
        Accept: "application/json",
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        //console.log(result);
        if (result.data != "") {
          setproductData(result.data);
        }
      })
    .catch((err) => {
      console.log(err.message);
    });
  }

  const ldJson =     {
    "@context":"https://schema.org",
    "@type":"Restaurant",
    "url":mainDomain+'/menu',
    "name":"Haiku - The Asian Kitchen",
    "description":"More than just Dining. Enjoy pasta making classes, lazy Sunday brunches, weekend breakfast and weekly Hi-tea, celebrity chef takeovers, art and music events ...",
    "servesCuisine":[
       "Italian Fusion"
    ],
    "hasMenu":{
       "@type":"Menu",
       "hasMenuSection":{
          "@type":"MenuSection",
          "name":"Food",
          "description":"More than just Dining. Enjoy pasta making classes, lazy Sunday brunches, weekend breakfast and weekly Hi-tea, celebrity chef takeovers, art and music events ...",
          "image":[
             "https://apimenutwo.haikutheasiankitchen.com/uploads/logo/original.png",
          ],
          // "offers":{
          //    "@type":"Offer",
          //    "availabilityEnds":"2017-03-02T08:22:00",
          //    "availabilityStarts":"2017-03-02T08:22:00"
          // },
          "hasMenuItem":productData.map((item) => (
                item.sub_cat.map((data) => ({
                    "@type": "MenuItem",
                    "name": data.name,
                    "description": data.description,
                    "offers": {
                        "@type": "Offer",
                        "price":  data.current_price,
                        "priceCurrency": "INR"
                    },
                    "nutrition": {
                        "@type": "NutritionInformation",
                        "calories": "170 calories",
                        "fatContent": "3 grams",
                        "fiberContent": "2 grams",
                        "proteinContent": "4 grams"
                    },
                    "suitableForDiet": "https://schema.org/GlutenFreeDiet"
                }))
            )),
          
          
       },
       "inLanguage":"English"
    }
 }

 const getImageSource = (typeId) => {
  if (typeId === '1') {
    return veg;
  } else if (typeId === '2') {
    return egg;
  } else if (typeId === '3') {
    return nonveg;
  } else  {
    return 'vegnonveg';
  }
};

let type="";
// const getPrice = (pricing,name) => {
//   // if (typeId === '1') {
//   //    type='veg';
//   // } else if (typeId === '2') {
//   //    type='egg';
//   // } else if (typeId === '3') {
//   //    type='nonveg';
//   // } else  {
//   //    type='vegnonveg';
//   // }
//   const price = pricing.split(',');
//   return price.map((item, index) => (
//     <span  key={index} className={item}>&#x20b9;{item} </span>
//   ));
// };

const getPrice = (pricing, name,dish_type) => {
  if (!pricing) {
    return null; // or return an empty array, depending on your requirements
  }

  const prices = pricing.split(',');
  const classNames = name.split(',');
  const dishtype = dish_type.split(',');

  return prices.map((item, index) => (
    <span key={index} className={dishtype&&dishtype[index]+' text-capitalize'}>{classNames[index]&& classNames[index]+' -'} &#x20b9;{item}</span>
  ));
};

const [show11, setShow11] = useState(false);
const handleshowpopup=()=>{
  //setShow11(true);
  
}

const handleClose=()=> {
  setShow11(false);

}
  return (
    <>
  <Header />
   <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson)}
      </script>
    </Helmet>
  <Stickyheader />
  <div className="container-fluid">
  <div className="filter-cont">
        {/* <div className="fiter-main">
          <img src={filter} alt="filter" />
          <span>Filter</span>
        </div> */}
        <div className="fiter-main">
          <label htmlFor="veg" className="container-radio"> 
            Veg
            <input type="checkbox" name="veg" id="veg" defaultValue="1" checked={checkedItems['veg'] || false}  onChange={handleCheckboxChange}/>
            <span className="checkmark" />
          </label>
        </div>
        {/* <div className="fiter-main">
          <label htmlFor="Egg" className="container-radio"> 
            Egg
            <input type="checkbox" name="Egg" id="Egg" defaultValue="Egg" />
            <span className="checkmark" />
          </label>
        </div> */}
        <div className="fiter-main">
          <label htmlFor="nonveg" className="container-radio">
            Non-Veg
            <input type="checkbox" name="nonveg" id="nonveg" defaultValue="3" checked={checkedItems['nonveg'] || false}  onChange={handleCheckboxChange}/>
            <span className="checkmark" />
          </label>
        </div>

        {/* <div className="fiter-main">
          <label htmlFor="vegnonveg" className="container-radio">
          Veg and Non-Veg
            <input type="checkbox" name="vegnonveg" id="vegnonveg" defaultValue="4" checked={checkedItems['vegnonveg'] || false}  onChange={handleCheckboxChange}/>
            <span className="checkmark" />
          </label>
        </div> */}
        {/* <div className="fiter-main">
          <label htmlFor="egg" className="container-radio">
            Egg
            <input type="checkbox" name="egg" id="egg" defaultValue="2" checked={checkedItems['egg'] || false}  onChange={handleCheckboxChange}/>
            <span className="checkmark" />
          </label>
        </div> */}

        
        <div className="fiter-main">
          <label htmlFor="popular" className="container-radio"> 
          Popular
            <input type="checkbox" name="popular" id="popular" defaultValue="popular" checked={checkedItems['popular'] || false}  onChange={handleCheckboxChange}/>
            <span className="checkmark" />
          </label>
        </div>
        <div className="fiter-main">
          <label htmlFor="Must_Try" className="container-radio"> 
          Must Try!
            <input type="checkbox" name="Must Try!" id="Must_Try" defaultValue="Must Try!" checked={checkedItems['Must Try!'] || false}  onChange={handleCheckboxChange}/>
            <span className="checkmark" />
          </label>
        </div>
        <div className="fiter-main">
          <label htmlFor="new" className="container-radio">
            New
            <input type="checkbox" name="new" id="new" defaultValue="NEW" checked={checkedItems['new'] || false}  onChange={handleCheckboxChange}/>
            <span className="checkmark" />
          </label>
        </div>
        {/* <div className="fiter-main">
          <label htmlFor="veg" className="container-radio"> 
            Trending
            <input type="checkbox" name="Trending" id="Trending" defaultValue="Trending" />
            <span className="checkmark" />
          </label>
        </div> */}
      </div>
     <form className="searchform">
      <input className="" type="text" onChange={handleSearch}  name="search"/>
      <div id="search"><img src={search}/></div>
     </form>


    {/* <div className="videodiv">
    {instastory&&instastory.map((item, i) => (
       <>
        <a href={item.link} >
            <div className="videotag" >
          
              <img src={item.web_featured_image} />
            
            </div>
          </a>
        </>
      ))}
    </div> */}
   
     {productData.map((item, i) => (
      <>
      {item.sub_cat.length > 0 &&
      <section className={`${activeStates[i] ? "active" : ""} ${scrolltop[i] ? "scroll" : ""} menuSection`} id={`section-${i}`} key={i}>
          <div className="menupagecard">
          <div className="strip"
                onClick={() => toggleAccordion(i)}
              >
              <span >{item.category}</span>
            </div>
            <div className="d-flex-cards" >
            {item.sub_cat.map(sub_cat => (
            
              <div className="card" key={sub_cat.id}>
                  <a href={"/menu-details/"+encodeURIComponent(sub_cat.name.replace(/ /g, '-'))+'-'+sub_cat.id}>
                  
                <figure>
                  <div className="img-wrap">
                    <img src={mainDomain+sub_cat.image} />
                  </div>
                  <figcaption>
                    <div className="patch">
                    <span className={sub_cat.product_status?sub_cat.product_status.toLowerCase():""} >{sub_cat.product_status}</span>
                    {getImageSource(sub_cat.type_id)=='vegnonveg'?(
                      <div>
                       <img src={veg} alt="" className="multicat"/>
                       <img src={nonveg} alt=""/>
                       </div>
                    ):(
                      <img src={getImageSource(sub_cat.type_id)} alt="" />
                    )}
                   
                    </div>
                    <h3 className="text-capitalize">{sub_cat.dish_name}</h3>
                    <p className="text-capitalize">{sub_cat.dish_description}</p>
                     <div className="cost">
                      {getPrice(sub_cat.price,sub_cat.dish_price_tag,sub_cat.dish_type)}
                      {/* {getPrice(sub_cat.current_price,sub_cat.type_id)} */}
                    </div>
                  
                    {/* {getPrice(sub_cat.current_price)?(
                      <>
                      {price.map((item, index) => (
                         <span className="cost" key={index}>&#x20b9; {item} hii</span>
                        
                      ))}
                      </>
                     )
                      :(
                      <span className="cost">&#x20b9; {sub_cat.current_price} bye</span>
                    )}
                     */}
                  </figcaption>
                </figure>
                </a>
              </div>
              ))}

            </div>
          </div>
        </section>
        
      }
      </>
      ))}



        {/* <section className="contact" id="Contact">
          <div className="container">
            <div className="d-flex">
              <div className="equal-cont">
                <span className="subtitle">REGISTER</span>
                <h2>Fill Form</h2>
                <form>
                  <input type="text" name="name" placeholder="Name" />
                  <input type="number" maxLength={10} name="phone" placeholder="Phone" />
                  <input type="email" name="email" placeholder="Email" />
                  <input type="text" name="password" placeholder="Password" />
                  <button type="submit" name="submit">Submit</button>
                </form>
              </div>
              <div className="equal-cont">
                <span className="subtitle">ADDRESS</span>
                <h2>About Us</h2>
                <div className="single-add">
                  <h3>Address</h3>
                  <p>2900 Lapeer Rd, Port Hurons, MI 49070</p>
                </div>
                <div className="single-add">
                  <h3>Phone</h3>
                  <p>8005550855</p>
                  <p>8005550855</p>
                </div>
                <div className="single-add">
                  <h3>Email</h3>
                  <p>info@companyname.com</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        
      </div>
      <div className="sticky-menu">
        <ul>
        {productData.map((item, i) => (
          <li className="footer-listitem"  onClick={() => linkredirect(i)} key={i}> <a href={'#section-'+i} >{item.category}</a></li>
        ))}
        </ul>
      </div>

</>
  )
}

export default Ordermenu;